* {
    margin: 0 auto;
    box-sizing: border-box;
    font-family: Open Sans,sans-serif;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: #f8f9fb;
}

#root {
    height: 100%;
}

#container {
    height: 100%;
}

/* Board styles */

#board {
    height: 93vh;
}

.currency {
    height: 8.3%;
    width: 100%;
    padding: 1%;
    border-bottom: 5px solid #f1f2f2;
}

.currency-icon {
    position: relative;
    float: left;
    height: 90%;
    width: 12%;
    top: 5%;
    left: 5%;
}

.currency-icon img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.currency-title {
    position: relative;
    float: left;
    height: 100%;
    width: 38%;
    color: #4b5662;
}

.currency-title-text {
    position: absolute;
    top: 10%;
    left: 18%;
    font-size: 3vh;
    font-weight: bold;
    text-transform: uppercase;
}

.currency-title-desc {
    position: absolute;
    top: 70%;
    left: 18%;
    font-size: 1vh;
    opacity: 0.8;
}

.purchase {
    position: relative;
    float: left;
    height: 100%;
    width: 25%;
    color: #4b5662;
}

.sale {
    position: relative;
    float: left;
    height: 100%;
    width: 25%;
    color: #4b5662;
}

.purchase-text, .sale-text {
    position: absolute;
    top: 25%;
    left: 10%;
    font-size: 3vh;
    font-weight: bold;
    text-transform: uppercase;
}

.sale-text {
    opacity: 0.8;
}

/* Board header styles */

#board-header {
    position: relative;
    height: 3vh;
    width: 100%;
    font-size: 2vh;
    padding-top: 2%;
    padding-bottom: 4%;
    border-bottom: 5px solid #f1f2f2;
}

.currency-header, .purchase-header, .sale-header {
    float: left;
    font-size: 1.3vh;
    text-transform: uppercase;
    position: absolute;
    color: #899099;
    font-weight: bold;
}

.currency-header {
    left: 21%;
}

.purchase-header {
    left: 55.5%;
}

.sale-header {
    left: 77.5%;
}

/* Board footer styles */

#board-footer {
    height: 3vh;
}

#footer-img {
    position: relative;
    width: 20%;
    height: 90%;
    background: url(../images/logo.png);
    background-size: 100% 100%;
}
